import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaBlueSky.module.css';

const IconSocialMediaBlueSky = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg 
      className={classes}
      fill="none" 
      viewBox="0 0 20 20" 
      width="24" 
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      >
      <title>Bluesky butterfly logo</title>
      <path fillRule="evenodd"
      d="M 4.118 1.13 C 6.298 2.77 8.639 6.096 9.5 7.881 L 9.5 12.597 C 9.5 12.495 9.461 12.609 9.377 12.853 C 8.929 14.178 7.175 19.34 3.165 15.211 C 1.054 13.041 2.033 10.866 5.877 10.212 C 3.676 10.586 1.206 9.965 0.527 7.533 C 0.332 6.833 0 2.526 0 1.946 C 0 -0.971 2.547 -0.054 4.118 1.13 Z M 14.882 1.13 C 12.702 2.77 10.361 6.096 9.5 7.881 L 9.5 12.597 C 9.5 12.495 9.539 12.609 9.623 12.853 C 10.071 14.178 11.825 19.34 15.835 15.211 C 17.945 13.041 16.967 10.866 13.123 10.212 C 15.323 10.586 17.793 9.965 18.473 7.533 C 18.667 6.833 19 2.526 19 1.946 C 19 -0.971 16.453 -0.054 14.882 1.13 Z">
      </path>
    </svg>
  );
};

IconSocialMediaBlueSky.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaBlueSky.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaBlueSky;
